import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import { TweenMax, TimelineLite, Power2, Linear, Elastic, CSSPlugin } from "gsap/all";
import { addEventListener, removeEventListener } from '../utils/EventUtils';


export default class ListArtistsController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );
        Logger.log( 'ListArtists->constructor()' );

        this.initDomElements();
        this.addEventListeners();
        this.initRevealTl();

        this.ready();
    }


    initDomElements() {
        var $mains = document.body.querySelectorAll( 'main' );
        this.$page = ( $mains.length > 1 ) ? $mains[1] : $mains[0];
        this.$searchInput = this.$page.querySelector( '.js-search-artists' );
        this.$listArtistsContainer = this.$page.querySelector( '.js-list-artists' );
        this.$listArtists = this.$page.querySelectorAll( '.js-list-artists-item a' );
    }


    addEventListeners() {
        if( this.$searchInput && this.$listArtists ) {
            this.searchListener = this.sortArtists.bind(this);
            this.$searchInput.addEventListener( 'keyup', this.searchListener);
        }
    }

    sortArtists() {
        var results = 0;
        var length = this.$searchInput.value.length;

        if( length > 0 ) {
            this.$listArtistsContainer.classList.add( 'is-searching' );
        } else {
            this.$listArtistsContainer.classList.remove( 'is-searching' );
        }

        for( var i = 0, j = this.$listArtists.length; i < j; i++ ) {
            let nameValue = this.$listArtists[i].getAttribute('data-text').removeAccent().toUpperCase();
            let searchValue = this.$searchInput.value.removeAccent().toUpperCase();
            if (nameValue.indexOf( searchValue ) > -1) {
                var wrappedMatch = "<span>"+ searchValue + "</span>"
                var newValue = nameValue.replace( searchValue, wrappedMatch );
                this.$listArtists[i].innerHTML = newValue;
                this.$listArtists[i].style.display = 'block';
                results++;
            } else {
                this.$listArtists[i].innerText = this.$listArtists[i].getAttribute('data-text');
                this.$listArtists[i].style.display = 'none';
            }
        }
        // Toggle no results
        if( results == 0 ) {
            this.$listArtistsContainer.classList.add( 'no-results' );
        } else {
            this.$listArtistsContainer.classList.remove( 'no-results' );
        }
    }


    initRevealTl() {
        // Els
        // var animatedTextIn = this.$page.querySelectorAll( '.animated-text' );

        this.revealTl = new TimelineLite( { paused : true } );

    }

    afterReveal(done = null) {

        // this.revealTl.play(0);

        super.afterReveal(done);
    }

    update() {}


    destroy() {
        Logger.log( 'ListArtists->destroy()' );
        if( this.$searchInput && this.$listArtists ) {
            this.$searchInput.removeEventListener( 'keyup', this.searchListener);
        }
    }
}
