import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import { TweenMax, TimelineLite, Power2, Linear, Elastic, CSSPlugin } from "gsap/all";
import { addEventListener, removeEventListener } from '../utils/EventUtils';


export default class SingleArtistController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );
        Logger.log( 'SingleArtist->constructor()' );

        this.initDomElements();
        this.addEventListeners();
        this.initRevealTl();

        this.ready();
    }


    initDomElements() {
        var $mains = document.body.querySelectorAll( 'main' );
        this.$page = ( $mains.length > 1 ) ? $mains[1] : $mains[0];
        this.$dropdowns = this.$page.querySelectorAll( '.js-dropdown-mobile' );
    }


    addEventListeners() {

        if( this.$dropdowns ) {
            this.dropdowns = [];
            this.dropdownHandlerListeners = [];
            for( var i = 0, j = this.$dropdowns.length; i < j; i++ ) {
                var dropdown = {
                    listener : this.dropdownClickHandler.bind(this, i),
                    cta : this.$dropdowns[i].parentNode.querySelector( '.js-mobile-dropdown-cta' )
                };
                this.dropdowns[i] = dropdown;
                this.dropdowns[i].cta.addEventListener( 'click', this.dropdowns[i].listener );
            }
            this.toggleGlobalReadMore();

            this.onResizeListener = this.toggleGlobalReadMore.bind( this );
            this.app.on( 'resize', this.onResizeListener );
        }

        // Video poster play btn listener
        this.$videoContainer = this.$page.querySelector( '.template-artist__video' );
        if( this.$videoContainer ) {
            this.$video = this.$videoContainer.querySelector( 'video' );
            this.$videoCta = this.$videoContainer.querySelector( '.u-play-button-wrapper' );
            if( this.$videoCta && this.$video ) {
                this.videoPlayingListener = () => {
                    this.$videoCta.style.opacity = 0;
                    this.$videoCta.style.pointerEvents = 'none';
                };
                this.videoPausedListener = () => {
                    this.$videoCta.style.opacity = 1;
                    this.$videoCta.style.pointerEvents = 'all';
                };
                this.$video.addEventListener("playing", this.videoPlayingListener);
                this.$video.addEventListener("pause", this.videoPausedListener);
            }

            this.videoCtaClickListener = this.togglePlay.bind(this);
            this.$videoCta.addEventListener("click", this.videoCtaClickListener);
        }

    }

    dropdownClickHandler(index) {
        // Check if it's open
        if( this.$dropdowns[index].classList.contains('open') ) {
            this.$dropdowns[index].classList.remove( 'open' );
            this.dropdowns[index].cta.classList.remove( 'active' );
            // var offset = this.$dropdowns[index].getBoundingClientRect().
            var offset = window.scrollY + this.$dropdowns[index].getBoundingClientRect().top - 140;
            window.scrollTo( 0, offset );
        } else {
            this.$dropdowns[index].classList.add( 'open' );
            this.dropdowns[index].cta.classList.add( 'active' );
        }
        // Trigger resize AOS (fix trigger animation on page height changing because of dropdown opening (ex: click read more => landscape <=> portrait then close)
        this.app.Animations.aosRefresh();
    }

    toggleGlobalReadMore() {
        for( var i = 0, j = this.dropdowns.length; i < j; i++ ) {
            // Store initial state
            var wasOpened = ( this.$dropdowns[i].classList.contains( 'open' ) ) ? true : false;

            this.$dropdowns[i].classList.remove( 'open' );
            var maxHeight = this.$dropdowns[i].getBoundingClientRect().height;
            // Open to get real height
            this.$dropdowns[i].classList.add( 'open' );
            var height = this.$dropdowns[i].getBoundingClientRect().height - 19; // marginBottom of column--left
            if( maxHeight < height ) {
                this.$dropdowns[i].classList.remove('disabled');
                this.dropdowns[i].cta.classList.remove('hide');
            } else {
                this.$dropdowns[i].classList.add('disabled');
                this.dropdowns[i].cta.classList.add('hide');
            }

            if( wasOpened == true ) {
                this.$dropdowns[i].classList.add( 'open' );
                this.dropdowns[i].cta.classList.add( 'active' );
            } else {
                this.$dropdowns[i].classList.remove( 'open' );
                this.dropdowns[i].cta.classList.remove( 'active' );
            }
        }
    }

    togglePlay() {
        if (this.$video.paused || this.$video.ended) {
            this.$video.play();
        } else {
            this.$video.pause();
        }
    }


    initRevealTl() {
        this.revealTl = new TimelineLite( { paused : true } );
    }

    afterReveal(done = null) {

        // this.revealTl.play(0);

        super.afterReveal(done);
    }

    update() {}


    destroy() {
        Logger.log( 'SingleArtist->destroy()' );
        if( this.$dropdowns ) {
            for( var i = 0, j = this.$dropdowns.length; i < j; i++ ) {
                this.dropdowns[i].cta.removeEventListener( 'click', this.dropdowns[i].listener );
            }
            this.dropdowns = [];
            this.app.off( 'resize', this.onResizeListener );
        }
        if( this.$videoContainer && this.$video ) {
            this.$video.removeEventListener("playing", this.videoPlayingListener);
            this.$video.removeEventListener("pause", this.videoPausedListener);
        }
        if( this.$videoCta ) {
            this.$videoCta.removeEventListener("click", this.videoCtaClickListener);
        }
    }
}
