import EventEmitter from 'eventemitter3';
import AppController from './AppController';


export default class AbstractPageController extends EventEmitter {
    constructor( callback = null ) {
        super();
        this.readyCallback = callback;

        // Checking abstract class instantiation
        if ( this.constructor === AbstractPageController ) {
            throw new TypeError( 'Abstract class "AbstractPageController" cannot be instantiated directly.' ); 
        }

        // Checking abstract methods
        if ( this.destroy === undefined ) {
            throw new TypeError( 'Classes extending the "AbstractPageController" abstract class need to implement all required methods.' );
        }

        // Default fields
        this.app = AppController.getInstance();
    }

    
    needsLoader() {
        return true;
    }

    
    ready() {
        if ( this.readyCallback ) {
            setTimeout( () => this.readyCallback(), 0 );
        }
    }
    

    beforeReveal( done = null ) {
        if ( done ) {
            done();
        }
    }


    afterReveal( done = null ) {

        this.app.Menu.updateActive();

        if ( done ) {
            done();
        }
    }


    beforeLeave( done = null ) {
        if ( done ) {
            done();
        }
    }


    update() {}


    // To be implemented
    // abstract destroy() {}
}
