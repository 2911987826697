import { addClass, removeClass } from '../utils/CssUtils';
import { addEventListener, preventDefault } from '../utils/EventUtils';
import Logger from '../utils/Logger';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { TweenMax, TimelineLite, Power2, Linear, Elastic, CSSPlugin } from "gsap/all";


export default class SiteMenu {
    constructor( app ) {
        Logger.log( 'SiteMenu->constructor()' );

        this.app = app;
        this.classname = 'c-site-menu';
        this.isMenuOpen = false;

        this.lastScrollPos = 0;

        this.initDomElements();
        this.addEventListeners();
        this.initTimelines();
    }


    initDomElements() {
        this.$menu = document.querySelector( '.' + this.classname );
        this.$navbar = document.querySelector( '.' + this.classname + '__top-bar' );
        this.$cta = this.$menu.querySelector( '.' + this.classname + '__top-bar__burger' );
        this.$menuContainer = this.$menu.querySelector( '.' + this.classname + '__content' );
        this.$menuItems = this.$menu.querySelectorAll( '.' + this.classname + '__content__item' );
        this.$menuItemsLinks = this.$menu.querySelectorAll( '.' + this.classname + '__content__item a' );
    }


    addEventListeners() {
        addEventListener( this.$cta, 'click', this.toggleMenu.bind( this ) );
        addEventListener( this.$menuItemsLinks, 'click', () => {
            if( this.isMenuOpen == true ) {
                this.closeMenu();
            }
        } );

        window.onscroll = () => {
            this.enableNavBar();
        };
    }

    initTimelines() {

        this._openTl = new TimelineLite(
            {
                paused: true,
                onStart: () => {
                },
                onComplete: () => {
                    TweenMax.set( this.$menuItemsLinks, { clearProps: 'all' });
                },
                onReverseComplete: () => {
                },
                onUpdate: () => {
                }
            });

        this._openTl
            .fromTo( this.$menuContainer, 0.45, { maxHeight: 0 }, { maxHeight: '100%', ease: Linear.easeOut }, 'start' )
            .staggerFromTo( this.$menuItemsLinks, 0.95, { autoAlpha:0, y: 35 }, { autoAlpha: 1, y: 0, ease: Power2.easeOut, force3D: true }, 0.15, 'start+=0.5' );

    }


    toggleMenu() {
        if ( this.isMenuOpen ) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }


    openMenu() {
        if ( !this.isMenuOpen ) {
            this.isMenuOpen = true;
            addClass( this.$menuItems, 'animate-in' );
            addClass( this.$menu, 'menu-is-open' );
            if( this.closeTimeout )
                clearTimeout( this.closeTimeout );
            addClass( this.$cta, 'active' );
            this._openTl.play();
            disableBodyScroll( this.$menu );
        }
    }


    closeMenu() {
        if ( this.isMenuOpen ) {
            clearAllBodyScrollLocks();
            removeClass( this.$menuItems, 'animate-in' );
            removeClass( this.$cta, 'active' );
            this.closeTimeout = setTimeout( () => {
                removeClass( this.$menu, 'menu-is-open' );
            }, (this._openTl.duration() * this._openTl.progress() ) * 1000 - 300 );
            this._openTl.reverse();
            this.isMenuOpen = false;
        }
    }

    enableNavBar() {
        if( this.isMenuOpen == false && window.pageYOffset > 80 ) {
            if ( window.pageYOffset < this.lastScrollPos )
                this.$navbar.classList.remove('minified');
            if ( window.pageYOffset > this.lastScrollPos )
                this.$navbar.classList.add('minified');
        } else {
            this.$navbar.classList.remove('minified');
        }

        this.lastScrollPos = window.pageYOffset;
    }

    updateActive() {
        // let current_url = window.location.href;
        //
        // /** Update new active page links **/
        // for( var i = 0, j = this.$menuItemsLinks.length; i < j; i++ )
        // {
        //     // Remove old active class
        //     if( this.$menuItemsLinks[i].classList.contains( 'active' ) )
        //         this.$menuItemsLinks[i].classList.remove( 'active' );
        //
        //     // Define new active if matching current url
        //     if( this.$menuItemsLinks[i].getAttribute( 'href' ) == current_url )
        //         this.$menuItemsLinks[i].classList.add( 'active' );
        // }

    }
}